<template>
  <div class="main">
    <!-- loading -->
    <div
      class="loadings"
      v-if="loading"
      v-loading="{ visible: loading, text: loadingtext }"
    ></div>
    <newhead />

    <div class="ny-zhuti">
      <!-- 话术模板的说明 技术报告专用 -->
      <div class="jishubaogaoshuoming" v-show="jsbg.sm && jsbg.sm!=''">
        <div class="jishubaogaoshuoming-title">{{jsbg.title}}</div>
        <div class="jishubaogaoshuoming-con">{{jsbg.sm}}</div>
      </div>
      <div class="ny-neirong">
        <div class="ny-con">
          <div class="tjlw">
            
            <div class="tjlw-title">写作示例</div>
            <el-form
              :label-position="'top'"
              label-width="200px"
              :model="form"
              style="max-width: 800px"
            >
              <el-form-item label="关键词" class="biaotiyouhua-item">
                <el-input
                  v-model="title.bt"
                  placeholder="必填，建议至少3个关键词"
                />
              </el-form-item>
              <el-form-item label="附加资料" class="biaotiyouhua-item">
                <el-input
                  rows="3"
                  type="textarea"
                  v-model="title.changwenben"
                  placeholder="选填，请输入附加资料，长度5000字以内"
                />
              </el-form-item>
              <el-form-item label="请选择类型">
                <el-radio-group v-model="moban.moban" @change="changemoban">
                  <el-radio
                    v-for="(item, key) in moban.options"
                    :key="key"
                    :value="item.mbid"
                    :label="item.mbname"
                  ></el-radio>
                </el-radio-group>
              </el-form-item>

              <!-- 除了医学之外的其他类型 显示-->
              <div v-show="moban.index!='-1'">
                <div class="ckwx-btn">
                  <el-button type="primary" @click="shengchengbiaoti"
                    >生成标题</el-button
                  >
                </div>
                <el-form-item
                  class="titleClass"
                  label="选择标题"
                  v-if="title.titles.length != 0"
                >
                  <el-radio-group v-model="title.title">
                    <el-radio
                      :value="item"
                      v-for="item in title.titles"
                      :key="item"
                      :label="item"
                    ></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="标题（可编辑修改）">
                  <el-input v-model="title.title" />
                </el-form-item>
              </div>

              <!-- 医学类型 显示-->
              <div v-show="moban.index=='-1'">
                <div class="ckwx-btn">
                  <el-button type="primary" @click="shengchengbiaotiyiliao"
                    >生成标题</el-button
                  >
                </div>
                <el-form-item
                  class="titleClass"
                  label="选择标题"
                  v-if="title.titles.length != 0"
                >
                  <el-radio-group v-model="title.title">
                    <el-radio
                      :value="item"
                      v-for="item in title.titles"
                      :key="item"
                      :label="item"
                    ></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="标题（可编辑修改）">
                  <el-input v-model="title.title" />
                </el-form-item>

                <div class="ckwx-btn">
                  <el-button type="primary" @click="shengchengmoxingyiliao"
                    >选择科室</el-button
                  >
                </div>
                <el-form-item class="titleClass" label="所属科室" v-if="moban2.options.length>0">
                  <el-radio-group v-model="moban2.moban" @change="changemobanyiliao">
                    <el-radio
                      v-for="(item, key) in moban2.options"
                      :key="key"
                      :value="item"
                      :label="item"
                    ></el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              

              <div class="tjlw-btns">
                <el-button size="large" type="primary" @click="shengchengbaogao"
                  >生成文章</el-button
                >
                <el-button size="large" plain @click="clickckjg"
                  >查看结果</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <!-- 付款弹窗 -->
    <el-dialog v-model="dialogTableVisible" title="付费提示" width="500" :close-on-click-modal='false' @close="qingchudingshiqi">
      <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
         <el-image style="width: 200px; height: 200px" :src="saomaurl" :fit="fit" />
        <div style="color:red;font-weight:bold;margin:20px 0 10px 0;font-size:24px">¥ {{price}}</div>
        <div style="margin-bottom:20px">您账户积分不足，请扫码付费完成本次操作</div>
      </div>
    </el-dialog>

    <newfooter />
  </div>
</template>

<script setup>
import {
  Delete,
  Edit,
  Search,
  Share,
  TrophyBase,
  Refresh,
} from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// 科普类型的index
let kepuindex='70'
// 付款
let dialogTableVisible=ref(false)
let saomaurl=ref('')
let orderid=ref(0)
let price=ref(0)
let timer=0 //setInterval返回值
// loading
let loading = ref(false);
let loadingtext = ref("生成中，请等待...");
let randsum = 0;
let form = reactive({});

// 保存接口返回的内容
let resdata=reactive([])
let jsbg=reactive({
  title:'',
  sm:'',
})

// 第一层模板
let moban = reactive({
  id: 0, //提交接口的模版
  index:0,//选择模型的时候保存Id，主要区分医学，科普，还有其他
  constindex:0,//生成标题的时候选择的模型index，(字段：Id)主要区分医学，科普，还有其他
  moban: "",
  options: [
    {
      Id:'-1',
      mbid:'-1',
      mbname:'医学',
    }
  ],
});

// 第二层模版（医学生成的动态模版）
let moban2=reactive({
  id: 0,
  moban: "",
  options: [],
  mobanall:[],
})


// 标题
let title = reactive({
  bt: "", //输入的关键词
  changwenben:'',//输入的长文本
  fanhuichangwenben:'',//接口返回的长文本
  keys: "", //接口返回的关键词
  titles:[],//最终获取的选题集合
  title:'',//最终选择的标题
});
// 参考文献
let cankaowenxian = reactive({
  cankaowenxian: "", //40条参考文献
  cankaowenxianjingxuan: "", //典型精选
});
// 潜在规律
let qianzaiguilv = reactive({
  qianzaiguilvs: "", //返回的全部潜在规律
  qianzaiguilv: "", //选取的潜在规律
});
// 剩余金额
let shengyujine=ref(-1)

// 获取保存在本地的关键词和附加资料
const getguanjianci=()=>{
  let tempdata=window.localStorage.getItem('shuruneirong_xiezuo')
  if(tempdata && tempdata!=''){
    title.bt=JSON.parse(tempdata).bt
    title.changwenben=JSON.parse(tempdata).changwenben
  }
}
getguanjianci()

// 获取模板列表
const getmoban = () => {
  get("/api/UserLogin/GetMobanList", {
    typeid: 9,
  }).then((res) => {
    resdata.push(...res.data)
    moban.options.push(...res.data);
  });
};
getmoban();

// 选择分类下的模版
const changemoban = (val) => {
  moban.id = moban.options.filter((e) => {
    return val == e.mbname;
  })[0].mbid;
  moban.index=moban.options.filter((e) => {
    return val == e.mbname;
  })[0].Id;
  // getjsbgsm()
};

// 获取模版说明
const getjsbgsm=()=>{
  let tempobj=resdata.filter(e=>{
    return e.mbid==moban.id
  })
  if(tempobj.length>0){
    jsbg.sm=tempobj[0].mbdes
    jsbg.title=tempobj[0].mbname
  }
}

// 获取潜在规律
const huoquqianzaiguilv = async () => {
  return post("/api/Paper/GetQzglckwx", {
    kw: title.bt,
    txt: title.changwenben,
    mbid: moban.id,
  })
    .then((res) => {
      title.keys = res.data.kw;
      cankaowenxian.cankaowenxian = res.data.ckwx;
      cankaowenxian.cankaowenxianjingxuan = res.data.dxjx;
      qianzaiguilv.qianzaiguilvs = res.data.qzgl;
      title.fanhuichangwenben = res.data.subtxt
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 默认取潜在规律的第一条
const huoquqianzaiguilvval = async () => {
  return new Promise((resolve, reject) => {
    let tempqianzaiguilv = qianzaiguilv.qianzaiguilvs.replace(/\n\n/g, "\n");
    qianzaiguilv.qianzaiguilv=tempqianzaiguilv.split("\n")[2]
    resolve();
  });
};

// 获取标题
const huoqubiaoti = async () => {
  title.titles.length=0
  title.title=''
  return post("/api/Paper/GetTitleByQzglFree", {
    kw: title.bt,
    txt: title.changwenben + title.fanhuichangwenben,
    qbqzgl: qianzaiguilv.qianzaiguilvs,
    qzgl: qianzaiguilv.qianzaiguilv,
    ckwx: cankaowenxian.cankaowenxian,
    dxjx: cankaowenxian.cankaowenxianjingxuan,
    mbid: moban.id,
  })
    .then((res) => {
      // 替换\n\n为\n
      let temptitle2 = res.data.replace(/\n\n/g, "\n");
      title.titles.push(...temptitle2.split("\n"));
      // 默认选中第一条
      title.title=title.titles[1]
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 开始生成标题
const shengchengbiaoti = async () => {
  if (title.bt.trim() == "" || moban.id == 0) {
    ElMessage({
      message: "标题和模型不能为空",
      duration: 1000,
    });
    return;
  }
  // 保存填写的信息到本地
  window.localStorage.setItem('shuruneirong_xiezuo',JSON.stringify(title))


  loading.value = true;
  loadingtext.value = "正在生成中，当前进度0%，请耐心等待...";
  // 初始化
  randsum = 0;
  let conouttime = 60 + Math.floor(Math.random() * 5) + 1;
  loadingfun(conouttime);
  await huoquqianzaiguilv();
  await huoquqianzaiguilvval();
  await huoqubiaoti();

  // 保存选择的模型id
  moban.constindex=moban.index

  loading.value = false;
};

// ------医疗生成标题专用--------------------------------------------------------------------------------------------------

// 获取全部模版
const getmobanallyiliao = () => {
  get("/api/Paper/GetYywsmb", {
    typeid: 13,
  }).then((res) => {
    moban2.mobanall.push(...res.data);
  });
};
getmobanallyiliao();

// 选择分类下的模版
const changemobanyiliao = (val) => {
  let tempval=val.split('，')[0].split('、')[1]
  let tempmobanarr=moban2.mobanall.filter(e=>{return e.lxname==tempval})
      if(tempmobanarr.length==0){
        moban2.id=0
        ElMessageBox.alert('系统发现无法匹配相关内容，请重新输出正确的关键词！', "提示", {
          confirmButtonText: "好的",
        });
      }else{
        moban2.id=tempmobanarr[0].id
      }
  changemoban(moban2.moban)
};

// 获取潜在规律
const huoquqianzaiguilvyiliao = async () => {
  return post("/api/Paper/GetQzglckwxYyws", {
    kw: title.bt,
    txt: title.changwenben,
    mbid: 0,
  })
    .then((res) => {
      title.keys = res.data.kw;
      cankaowenxian.cankaowenxian = res.data.ckwx;
      cankaowenxian.cankaowenxianjingxuan = res.data.dxjx;
      qianzaiguilv.qianzaiguilvs = res.data.qzgl;
      title.fanhuichangwenben = res.data.subtxt;
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 默认取潜在规律的第一条
const huoquqianzaiguilvvalyiliao = async () => {
  return new Promise((resolve, reject) => {
    let tempqianzaiguilv = qianzaiguilv.qianzaiguilvs.replace(/\n\n/g, "\n");
    qianzaiguilv.qianzaiguilv = tempqianzaiguilv.split("\n")[2];
    resolve();
  });
};

// 获取标题
const huoqubiaotiyiliao = async () => {
  title.titles.length = 0;
  title.title=''
  return post("/api/Paper/GetTitleByQzglYywsFree", {
    kw: title.bt,
    txt: title.changwenben + title.fanhuichangwenben,
    txt2: "",
    qbqzgl: qianzaiguilv.qianzaiguilvs,
    qzgl: qianzaiguilv.qianzaiguilv,
    ckwx: cankaowenxian.cankaowenxian,
    dxjx: cankaowenxian.cankaowenxianjingxuan,
    mbid: 0,
  })
    .then((res) => {
      if(res.data==''){
        ElMessageBox.alert(
        "未找到适合您的标题，请完善关键词后重新点击生成标题",
        "提示",
        {
          confirmButtonText: "我知道了",
        }
      );
      return
      }
      // 替换\n\n为\n
      let temptitle2 = res.data.replace(/\n\n/g, "\n");
      title.titles.push(...temptitle2.split("\n"));
      // 默认选中第一条
      title.title = title.titles[0];
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 匹配模型
const shengchengmoxingyiliao = async () => {
  moban2.options.length=0
  moban2.moban=''
  loading.value = true;
  loadingtext.value = "正在生成中，请耐心等待...";
  return post("/api/Paper/GetYywsPpmb", {
    kw: title.keys,
    txt: title.title,
    ckwx: cankaowenxian.cankaowenxian,
    mbid: 0,
  })
    .then((res) => {
      loading.value = false;
      // 替换\n\n为\n
      let temphuashumoban = res.data.replace(/\n\n/g, "\n");
      moban2.options.push(...temphuashumoban.split("\n"))
      // moban2.moban=moban2.options[0]
      // changemobanyiliao(moban2.moban)
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};

// 开始生成标题
const shengchengbiaotiyiliao = async () => {
  if (title.bt.trim() == "") {
    ElMessage({
      message: "关键词不能为空",
      duration: 1000,
    });
    return;
  }
  // 保存填写的信息到本地
  window.localStorage.setItem('shuruneirong_xiezuo',JSON.stringify(title))
  
  loading.value = true;
  loadingtext.value = "正在生成中，当前进度0%，请耐心等待...";
  // 初始化
  randsum = 0;
  let conouttime = 60 + Math.floor(Math.random() * 5) + 1;
  loadingfun(conouttime);
  await huoquqianzaiguilvyiliao();
  await huoquqianzaiguilvvalyiliao();
  await huoqubiaotiyiliao();

  // 保存选择的模型id
  moban.constindex=moban.index

  loading.value = false;
};

// ---------医疗生成标题专用---------------------------------------------------------------------------------------------------

// 生成写作示例
const shengchengbaogao=async()=>{
  if (cankaowenxian.cankaowenxian == "") {
    ElMessage({
      message: "请生成标题后再生成文章！",
      duration: 1000,
    });
    return;
  }
  if (title.title.trim() == "") {
    ElMessage({
      message: "标题不能为空",
      duration: 1000,
    });
    return;
  }
  // 根据选择的模版id区分调用哪个接口生成文章
  let apitemp=''
  // 科普的id，固定值（后期确定，取mbid不是Id）
  if(moban.constindex==kepuindex){
    apitemp='/api/Paper/SubKpPaper'
  // 医疗的id，固定值（本地设定）
  }else if(moban.constindex=='-1'){
    apitemp='/api/Paper/SuYywsbLong'
    moban.id=moban2.id //保存医疗模版的id
  // 其他类型
  }else{
    apitemp='/api/Paper/SubViewPoint'
  }
  // 选择医疗的时候moban.id不能为0
  if(moban.index=='-1' &&  moban.id==0){
    ElMessage({
      message: "未选择有效的模版，无法生成文章",
      duration: 2000,
    });
    return;
  }
  loading.value=true
  loadingtext.value = "正在提交中，请耐心等待...";

  return post(apitemp, {
    mbid: moban.id,
    ybt:title.bt,
    title:title.title,
    subtxt:title.changwenben + title.fanhuichangwenben,
    kw: title.keys,
    ckwx: cankaowenxian.cankaowenxian,
    dxjx: cankaowenxian.cankaowenxianjingxuan,
    qzgl: qianzaiguilv.qianzaiguilv,
    qbqzgl: qianzaiguilv.qianzaiguilvs, 
    oid:orderid.value,
  })
    .then((res) => {
      loading.value=false
      // 清空
      title.bt = "";
      title.title = "";
      title.titles.length = []
      title.changwenben = "";
      cankaowenxian.cankaowenxian = ""
      // 清空localStorage
      window.localStorage.removeItem('shuruneirong_xiezuo')
      ElMessageBox.alert(`本次消耗${res.data.price}积分，剩余${res.data.syprice}积分。预计一个工作日内完成审核，结果可在“个人中心-写作记录”中查看。`,
        "提示",
        {
          confirmButtonText: "我知道了",
          callback: () => {
            clickckjg()
          },
        }
      );
      
      
    })
    .catch((err) => {
      loading.value = false;
      // 余额不足调用弹窗付款
      if(err.data.code===210){
        saomaurl.value="data:image/jpeg;base64,"+err.data.data.payimg
        orderid.value=err.data.data.oid
        price.value=err.data.data.price
        dialogTableVisible.value=true
        // 循环调用查询支付状态
        timer = setInterval(()=>{
          zhifuzhuangtai()
        },2000)
      }
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
}

// 查询支付状态
const zhifuzhuangtai=()=>{
  get("/api/WxPay/GetOrderStatus", {
    id: orderid.value,
  })
    .then((res) => {
      // 200支付成功，211未支付
      if(res.code===200){
        // 注销定时查询
        clearInterval(timer)
        ElMessage({
          message: '支付成功！',
          type: 'success',
          duration:1000,
          onClose:()=>{
            dialogTableVisible.value=false
            // 继续选题
            fukuanlijishengcheng()
          }
        })
      }
    })
}

// 支付后在生成
const fukuanlijishengcheng=async()=>{
  await shengchengbaogao();
  // 清空订单id
  orderid.value=0
}

// 清除定时器和订单id
const qingchudingshiqi=()=>{
  clearInterval(timer)
  orderid.value=0
}


// 查看结果
const clickckjg = () => {
  // 根据最后一篇论文生成时的选择的类型，到相应类型的论文记录列表
  let navindexval='2-4-1'
  let urltemp='/xiezuojilu'
  let pageid=21
  // 科普的index，固定值（后期确定，取Id）
  if(moban.constindex==kepuindex){
    navindexval='2-4-2'
    pageid=1
  // 医疗的id，固定值（本地设定）
  }else if(moban.constindex=='-1'){
    navindexval='2-4-1'
    pageid=21
  // 其他类型
  }else{
    navindexval='2-4-3'
    pageid=9
  }

  window.localStorage.setItem("navindex", navindexval);
  router.push({
    path: urltemp,
    query: {
      id:pageid
    },
  });
};

// loading
const loadingfun = (outtime) => {
  let randNumtemp = Math.floor(Math.random() * 5) + 1;
  if (loading.value) {
    setTimeout(() => {
      randsum += randNumtemp;
      if (randsum < outtime) {
        let temprandnum = ((randsum / outtime) * 100).toFixed(2);
        loadingtext.value = `正在生成中，当前进度${temprandnum}%，请耐心等待...`;
        loadingfun(outtime);
      } else {
        loadingtext.value = `正在生成中，当前进度99.99%，请耐心等待...`;
      }
    }, randNumtemp * 1000);
  }
};
</script>

<style scoped>
:deep(.titleClass .el-radio-group) {
  align-items: flex-start;
  flex-direction: column;
}
:deep(.titleClass .el-radio) {
  white-space: normal;
  height: auto;
  min-height: 32px;
  line-height: 20px;
  margin: 0 0 0 0;
}
:deep(.el-input-group__prepend .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.el-input-group__append .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.el-checkbox) {
  white-space: normal;
}
:deep(.el-form-item__label) {
  font-weight: bold;
}
:deep(.tjlw-btns .el-button) {
  width: 200px;
  height: 50px;
  font-size: 16px;
}
.main {
  width: 100%;
  height: 100%;
  background: #fff;
  min-width: 1200px;
}

.ny-zhuti {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: calc(100% - 100px);
}

/* 流程 */
.ny-liucheng {
  position: absolute;
  left: 200px;
  top: 0;
  width: 400px;
  height: 100%;
  overflow-y: scroll;
  background: url("/img/common/bg-side.9a695b46.png") no-repeat;
  background-size: 100% 100%;
}
.ny-liucheng-title {
  margin-top: 82px;
  margin-bottom: 56px;
  font-size: 28px;
  color: #48a4ff;
  text-align: center;

  opacity: 1;
}
.ny-liucheng-li-title {
  width: 320px;
  height: 38px;
  background: linear-gradient(
    270deg,
    rgba(218, 249, 248, 0.6),
    rgba(179, 225, 251, 0.6)
  );
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.51);
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-left: 12px;
  font-weight: bold;
}
.ny-liucheng-li {
  width: 320px;
  height: auto;
  font-size: 14px;
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.6);
  margin: 15px auto 20px;
}
.ny-liucheng-li-title-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(147deg, #48b6ff, #4891ff);
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin-right: 12px;
}

.ny-neirong {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}

.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.tjlw {
  width: 800px;
  margin: auto;
}
.tjlw-title {
  font-size: 30px;
  font-weight: 400;
  color: #3f4654;
  line-height: 42px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tjlw-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tjlw-img-txt {
  position: absolute;
  left: calc(50% - 400px);
  top: 36px;
  z-index: 1;
  width: 800px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.biaotiyouhua-item {
  position: relative;
}
.biaotiyouhua {
  position: absolute;
  left: 0;
  top: 35px;
  width: 800px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #fff;
  box-shadow: 0 6px 20px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #ececee;
  z-index: 10;
}
.biaotiyouhua-title {
  display: flex;
  align-items: center;
}
.biaotiyouhua-bt {
  padding: 2px 0;
}
.biaotiyouhua-bts {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.biaotiyouhua-bts:hover {
  background: #f1f5ff;
  color: #2489f2;
}
.biaotiyouhua-hyp {
  width: 70px;
  cursor: pointer;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  color: #2489f2;
}
.tjlw-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;
}
.cankaowx {
  width: 800px;
  height: 328px;
  overflow: hidden;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx2 {
  width: 800px;
  height: auto;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx-more {
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.cankaowx-more > span {
  cursor: pointer;
  color: #e6a23c;
}
.cankaowx-more > span:hover {
  text-decoration: underline;
}

.loadings {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.tjlw-btns-link {
  margin-left: 40px;
  font-size: 14px;
  color: #2489f2;
  cursor: pointer;
}
.tjlw-btns-link:hover {
  text-decoration: underline;
}
.ckwx-btn {
  padding: 10px 0 20px 0;
}
.tips{
  color: #999;
  font-size: 14px;
}
.jishubaogaoshuoming{
  position: absolute;
  right: calc(50% + 420px);
  top: 108px;
  z-index: 1;
  width: 360px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  font-size: 14px;
}
.jishubaogaoshuoming-title{
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
}


/* 美化滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.1);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
